



























































































import ActivityLogService from "@/services/ActivityLogService";
import LocationService from "@/services/LocationService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class ActivityLog extends Vue {
  private searchtext: string = "";
  private events: any[] = [];
  private currentPage: number = 1;
  private pagesize: number = 10;
  private sortBy = "createdDate";
  private sortDesc = false;
  private sortDirection = "desc";

  handlePageSizeChange() {
    this.currentPage = 1;
    this.handlePageChange();
  }

  sortChanged(data: any) {
    this.sortBy = data.sortBy;
    this.sortDesc = data.sortDesc;
    this.sortDirection = data.sortDesc ? "desc" : "asc";
    this.currentPage = 1;
    this.handlePageChange();
  }

  @Watch("searchtext")
  search() {
    this.reload();
  }

  @Watch("currentPage")
  handlePageChange() {
    this.reload();
  }

  reload() {
    ActivityLogService.search(
      this.searchtext,
      this.currentPage - 1,
      this.pagesize,
      `${this.sortBy},${this.sortDirection}`
    ).then((response) => {
      this.events = response.data;
    });
  }

  mounted() {
    this.reload();
  }
}
