import http from "../http-common";
import AuthService from "./AuthService";
import { DataService } from "./DataService";

class ActivityLogService extends DataService {

  search(search: string, page: number = 0, size: number = 0, sort: string = "") {
    return http.get(`${this.url}`, { headers: { Authorization: AuthService.token() }, params: { search: search, page: page, size: size, sort: sort } });
  }

}

export default new ActivityLogService('/activitylog');